import {AppConfig} from '../config/config.types'

export function getSeoUrl(config: AppConfig, path?: string): string {
  let pathWithSlash = ''

  if (path) {
    if (path.charAt(0) !== '/') {
      pathWithSlash = '/' + path
    } else {
      pathWithSlash = path
    }
  }
  return `${config.protocol}://${config.seoDomain}${pathWithSlash}`
}
